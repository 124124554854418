.upload-images {
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	overflow-x: auto;

	.fileContainer .uploadPictureContainer img.uploadPicture {
		width: unset !important;
	}

	.uploadPicturesWrapper > div:first-child {
		flex-wrap: unset !important;
	}

	.fileContainer .deleteImage {
		font-size: 10px !important;
		line-height: 20px !important;
		width: 20px !important;
		height: 20px !important;
	}

	.fileContainer .uploadPictureContainer {
		width: 150px !important ;
		margin: 5px !important;
		padding: 5px !important;
		box-shadow: unset !important;
		background: unset !important;
		border: unset !important;
	}
}