/*    TABLES    */

table {
	caption {
		margin-bottom: 8px;
	}
	border-spacing: 0px;
}

.table-responsive {
	overflow-x: auto;

	table {
		width: 100%;
	}
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.table-auto {
	table-layout: auto;
}

.table-equal {
	table-layout: fixed;
}

/* CARD */

.card-table {
	box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-thumb {
		background-color: #cccccc;
	}

	table {
		background-color: var(--white);
		padding: 0em;
		border-radius: 7px;
	}

	th {
		padding: $paddingNormal;
		line-height: 1.3rem;
		background-color: rgba(white, 0.3);
		border-bottom: 2px solid rgba($primary, 0.3);
		color: var(--darkgray);
	}

	td {
		padding: $paddingMini $paddingNormal;
		line-height: 1.3rem;
		border-bottom: 2px solid rgba(#c8c9c7, 0.2);
	}

	thead {
		tr {
			&:hover {
				background-color: var(--white);
			}
		}
	}

	tr {
		transition: 300ms ease-in-out;
		height: 2.5em;
		&.row-active {
			background-color: #c3c3c3;
		}

		&:hover {
			background-color: rgba(#f2f2f2, 0.6);
		}
	}

	.th-sm {
		width: 94px !important;
	}

	.th-md {
		width: 120px !important;
	}

	.th-lg {
		width: 180px !important;
	}

	.th-xl {
		width: 250px !important;
	}
}


.card-table-modal{
	min-height: 250px;
}
