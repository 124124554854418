.worko-tabs {
	.state {
		position: absolute;
		left: -10000px;
	}

	.flex-tabs {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.tab {
			flex-grow: 1;
			max-height: 40px;
			font-weight: 600;
		}

		.panel {
			background-color: #fff;
			padding: 20px;
			min-height: 300px;
			display: none;
			width: 100%;
			flex-basis: auto;
		}
	}

	.tab {
		display: inline-block;
		padding: 10px;
		vertical-align: top;
		background-color: #eee;
		cursor: hand;
		cursor: pointer;
		border-bottom: 2px solid rgba(#c8c9c7, 0.2);

		&:hover {
			background-color: #fff;
		}
	}
}

#tab-one:checked ~ .tabs #tab-one-label,
#tab-two:checked ~ .tabs #tab-two-label,
#tab-three:checked ~ .tabs #tab-three-label,
#tab-four:checked ~ .tabs #tab-four-label,
#tab-five:checked ~ .tabs #tab-five-label {
	background-color: #fff;
	cursor: default;
	border-bottom-color: var(--primary);
	color: var(--black);
}

#tab-one:checked ~ .tabs #tab-one-panel,
#tab-two:checked ~ .tabs #tab-two-panel,
#tab-three:checked ~ .tabs #tab-three-panel,
#tab-four:checked ~ .tabs #tab-four-panel,
#tab-five:checked ~ .tabs #tab-five-panel {
	display: block;
}

@media (max-width: 600px) {
	.flex-tabs {
		flex-direction: column;

		.tab {
			background: #fff;
			border-bottom: 1px solid #ccc;

			&:last-of-type {
				border-bottom: none;
			}
		}

		#tab-one-label {
			order: 1;
		}
		#tab-two-label {
			order: 3;
		}
		#tab-three-label {
			order: 5;
		}
		#tab-four-label {
			order: 7;
		}
		#tab-one-panel {
			order: 2;
		}
		#tab-two-panel {
			order: 4;
		}
		#tab-three-panel {
			order: 6;
		}
		#tab-four-panel {
			order: 8;
		}
	}

	#tab-one:checked ~ .tabs #tab-one-label,
	#tab-two:checked ~ .tabs #tab-two-label,
	#tab-three:checked ~ .tabs #tab-three-label,
	#tab-four:checked ~ .tabs #tab-four-label {
		border-bottom: none;
	}

	#tab-one:checked ~ .tabs #tab-one-panel,
	#tab-two:checked ~ .tabs #tab-two-panel,
	#tab-three:checked ~ .tabs #tab-three-panel,
	#tab-four:checked ~ .tabs #tab-four-panel {
		border-bottom: 1px solid #ccc;
	}
}
