.sub-menu {
	border-bottom: 0.5px solid var(--lightgray);

	.item-sub {
		padding: $paddingSmall;
		padding-bottom: 0.5em;
	}

	.item-sub:hover {
		border-bottom: 3px solid var(--gray);
	}

	.active {
		border-bottom: 3px solid var(--primary);
		font-weight: $weightSemi;
	}

	.active:hover {
		border-bottom: 3px solid var(--primary);
	}
}
