/*
 * @version 1.1.0
 * @description Estilos de la página
*/

.page-home {
    
    background-color: var(--lightBg);

}

.list-side {
    .list-item {
        padding: 0.9em;
        border-bottom: 1.5px solid rgba(#838182,0.1);
        font-weight: $weightSemi;
        transition: all 300ms;

        &:hover {
            color: var(--darkgray);
            border-bottom: 1.5px solid rgba($primary, 0.6);
            // font-weight: $weightSemi;
        }

        &.active {
            color: var(--darkgray);
            border-bottom: 1.5px solid rgba($primary, 0.6);
            background-color: var(--white);
            // font-weight: $weightSemi;
        }
    }
}

.table-side {
    background-color: var(--white);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}