/*LEFT SIDEBAR*/
.menu {
	position: fixed;
	left: 0px;
	background-color: var(--white);
	height: 100%;
	width: 0px;
	overflow-x: hidden;
	transition: 300ms;
	z-index: 1;
	padding-top: $paddingSemi;

	&.open {
		width: 100%;
	}

	.menu-nav {
		color: var(--secondary);
		:hover {
			color: var(--primary);
		}
	}

	.menu-nav:hover {
		color: var(--primary);
	}

	.column-menu {
		border-right: 1px solid var(--lightgray);
		width: 60%;
	}

	// .column-submenu {
	// }
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {

	.menu {
		.half{
			width: 90%;
		}


		.column-menu{
			width: 70%;
		}
	}
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
}
