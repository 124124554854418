/**
*   login.scss
*   @description: Estilos para la página de Login
*/

.login {
	height: 100vh;
	background-image: url("/img/bg-3.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;

	.content {
		height: 100vh;
		width: 100%;
	}

	.card-login {
		width: 100%;
		height: 100%;
		background-color: #303030c7;
		box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
		img {
			height: 100px;
		}
	}

	.label-login {
		width: 80%;
	}

	.input-container {
		input {
			width: 80%;
			padding: $paddingSmall 0px;
			color: var(--dark);
			background-color: transparent;
			font-family: $Mukta;
			line-height: 1.5em;
			outline: none;
			border-bottom: 2px solid var(--lightgray);
			color: var(--white);

			&:focus {
				border-bottom: 2px solid var(--primary);
			}
			&::placeholder {
				color: #ffffffe1;
				font-weight: normal;
			}
		}
	}

	.btn-container {
		button {
			width: 80%;
			padding: $paddingSmall;
		}
	}

	.error-login {
		background-color: #c03a2b69;
	}

	.success-login {
		background-color: #27ae5f69;
	}
}





/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {

	.login {
		.colum-space{
			width: 0px!important;
		}
	}
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
}

