#reporter {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   border: 1px solid #797979;
   background-color: #797979;
   width: 100%;
   position: relative;
   overflow-y: hidden;

   #reporter_tools {
      position: absolute;
      right: 20px;
      top: 10px;
      z-index: 2;

      button {
         background-color: $primary;
         color: white;
         padding: 5px;
         padding-left: 8px;
         padding-right: 8px;
         border-radius: 5px;
         margin-left: 5px;
      }
   }

   #reporter_main {
      overflow-y: scroll;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   #reporter_content {
      height: 100%;
      width: 92%;
      flex-direction: column;
      justify-content: center;
   }

   .reporter_sheet {
      display: flex;
      border: 1px solid #797979;
      background-color: #ffffff;

      .reporter_content {
         display: flex;
         transform: scale(0.9);
         margin-left: 10px;
         margin-right: 10px;
         width: 100%;

         table {
            width: 100%;
            height: 100%;
         }
      }
   }

   .jump-page {
      height: 20px;
   }
}
