/**
*   inputs.scss
*   @description: Estilos para los inputs
*/

input,
select,
option,
textarea {
	font-family: $Mukta;
	line-height: 1.5em;
}

input:focus,
select:focus,
option:focus,
textarea:focus,
button:focus {
	outline: none;
}

select:disabled,
input:disabled {
	background-color: #ebebe4;
}

.input {
	color: var(--dark);
	padding: 0.2em;
	border-bottom: 2px solid var(--lightgray);

	&:focus {
		border-bottom: 2px solid var(--primary);
	}
}

.disabled {
	background: red;
}

.input-clean {
	position: relative;
	height: 42px;
	width: 30%;

	i {
		position: absolute;
		width: 4%;
		left: 13px;
		top: 8px;
		color: #9e9e9e;
		cursor: none;
		font-size: 0.9rem;
	}

	.input-filter {
		width: 100%;
		padding: 5px 12px 5px 35px;
		background-color: transparent;
		border-bottom: 2px solid var(--lightgray);

		&:focus {
			border-bottom: 2px solid var(--primary);
		}

		&:active {
			border: 0px !important;
		}
	}
}

.text-small {
	width: 50px;
}

.text-normal {
	width: 80px;
}

.text-big {
	width: 120px;
}

.input-small {
	width: 50% !important;
}

.input-mini {
	width: 45% !important;
}

.input-medium {
	width: 58% !important;
}

.input-huge {
	width: 95% !important;
}

/* The container */
.container-check {
	display: block;
	height: 25px;
	// width:25px;
	position: relative;
	padding-right: 30px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 0;
}

/* Hide the browser's default checkbox */
.container-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	border-radius: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	border: 1.5px solid rgba(131, 129, 130, 0.3);
	//top: 0;
	//left: 0;
	height: 25px;
	width: 25px;
	background-color: var(--white);
	border-radius: 0.5px solid gray !important;
	-webkit-border-radius: 0.5px solid gray !important;
	-moz-border-radius: 0.5px solid gray !important;
	-ms-border-radius: 0.5px solid gray !important;
	-o-border-radius: 0.5px solid gray !important;
}

/* On mouse-over, add a grey background color */
.container-check:hover input~.checkmark {
	background-color: var(--white);
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked~.checkmark {
	background-color: var(--white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container-check input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
	left: 9px;
	top: 5px;
	width: 8px;
	height: 13px;
	border: solid var(--primary);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}