.card-table table {
   padding: 15px;
}

.custom-td {
   /* Define el ancho mínimo del td */
   min-width: 1px;
   /* Hace que el td crezca de acuerdo al contenido */
   width: auto;
}

.custom-input {
   /* Hace que el input se adapte al contenido */
   width: 100%;
   /* Quita el espacio extra que algunos navegadores añaden */
   box-sizing: border-box;
   /* Asegúrate de que el input no exceda el ancho del td */
   max-width: 100%;
}

.mt-search {
   margin-top: 1rem;
}

.flex-container {
   display: flex;
}

.justify-between {
   justify-content: space-between;
}

.gap-15 {
   gap: 15px;
}

.gap-20 {
   gap: 20px;
}

.gap-30 {
   gap: 30px;
}

//Componente para gráficas
.graph-card {
   color: #fff;
   width: 25%;
   border-radius: 10px;
   overflow: hidden;
   h4,
   p {
      color: #fff;
   }

   .card-header {
      color: #fff;
      background: #d4b467;
      padding: 5px 10px;
      i {
         font-size: 20px;
      }
   }

   .card-content {
      background: #18191a;
      padding: 10px;
      .icon-text {
         .icon-container {
            padding: 10px;
            border-radius: 15px;
            color: #d4b467;
            i {
               font-size: 24px;
            }
         }

         .text-container {
            flex-grow: 1;
            p {
               font-family: monospace;
               font-size: 16px;
            }
         }
      }
   }
}

@media screen and (max-width: 768px) {
   .flex-container {
      flex-direction: column;
   }
}
