/* tooltip css */
/* https://uiverse.io/vnuny/stale-mouse-74 */

/* From Uiverse.io by vnuny */
.item-hints {
   position: relative;
   display: flex;
   justify-content: flex-start;
}
.item-hints .hint {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
}
.item-hints .hint-dot {
   z-index: 3;
   width: 60px;
   height: 60px;
   -webkit-transform: translate(-0%, -0%) scale(0.95);
   transform: translate(-0%, -0%) scale(0.95);
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
}
.item-hints .hint-radius {
   background-color: rgba(255, 255, 255, 0.1);
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   margin: -125px 0 0 -125px;
   opacity: 0;
   visibility: hidden;
   -webkit-transform: scale(0);
   transform: scale(0);
}
.item-hints .hint[data-position='1'] .hint-content {
   top: 85px;
   left: 50%;
}
.item-hints .hint-content {
   width: 300px;
   position: absolute;
   z-index: 5;
   padding: 35px 0;
   opacity: 0;
   transition: opacity 0.7s ease, visibility 0.7s ease;
   color: #fff;
   visibility: hidden;
   pointer-events: none;
}
.item-hints .hint:hover .hint-content {
   position: fixed;
   z-index: 5;
   padding: 35px 0;
   opacity: 1;
   -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
   transition: opacity 0.7s ease, visibility 0.7s ease;
   color: #fff;
   visibility: visible;
   pointer-events: none;
}
.item-hints .hint-content::before {
   width: 0px;
   bottom: 29px;
   left: 0;
   content: '';
   background-color: #fff;
   height: 1px;
   position: absolute;
   transition: width 0.4s;
}
.item-hints .hint:hover .hint-content::before {
   width: 180px;
   transition: width 0.4s;
}
.item-hints .hint-content::after {
   -webkit-transform-origin: 0 50%;
   transform-origin: 0 50%;
   -webkit-transform: rotate(-225deg);
   transform: rotate(-225deg);
   bottom: 29px;
   left: 0;
   width: 80px;
   content: '';
   background-color: #fff;
   height: 1px;
   position: absolute;
   opacity: 1;
   -webkit-transition: opacity 0.5s ease;
   transition: opacity 0.5s ease;
   -webkit-transition-delay: 0s;
   transition-delay: 0s;
}
.item-hints .hint:hover .hint-content::after {
   opacity: 1;
   visibility: visible;
}
.item-hints .hint[data-position='4'] .hint-content {
   top: 35%;
   right: -350px;
}
.hint-content p {
   background-color: antiquewhite;
   color: #18191a;
   line-height: 1rem;
   padding: 10px 0 10px 10px;
}

@media (max-width: 1400px) {
   .item-hints .hint[data-position='4'] .hint-content {
      right: -250px;
   }
}
@media (max-width: 950px) {
   .item-hints .hint[data-position='4'] .hint-content {
      right: -150px;
   }
}

@media (max-width: 768px) {
   .item-hints .hint[data-position='4'] .hint-content {
      right: -50px;
   }
}
