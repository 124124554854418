/**
*   header.scss
*   @description: Estilos del Header
*/
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

header {
    position: sticky;
    top: 0px;
    padding: $paddingSmall 0;
    background-color: #18191a;
	z-index: 2;

    .options {
        width: 50%;
    }

    img {
        height: 45px;
        // width: 60px;
    }
    .nav-item {
        padding: 0 $paddingNormal;
        border-bottom: 2px solid transparent;
        transition: border-bottom 300ms ease-in-out;
        margin-left: 8px;
        padding: 4px 4px;
        &:hover { border-bottom: 2px solid var(--white); }
        i { padding-right: 4px; }
    }
    .nav-size{
        width: 192px;
    }

    .nav-active {
        border-bottom: 2px solid var(--gray);
        margin-left: 8px;
        padding: 4px 4px;
    }

}

.header {
    height: 100px;
    background-color: white;
    border: 1px solid #e9ebed;

    .logo {
        padding-left: 48px;

        i {
            color: $secondary;
            transition: color .3s ease-in-out;
            font-size: 1.2rem;
            cursor: pointer;
        }
    }

    .sesion {
        padding-right: 36px;

        i {
            color: $secondary;
            padding-right: 8px;
        }

        .left {
            padding-right: 32px;
        }

        .right {
            i {
                color: $primary;
            }
        }
    }
}

.header-modals {
    padding: 8px 8px 8px 16px;
    border-radius: 4px 4px 0 0;
    background-color: var(--black);
    width: 100%;
    position: relative;

    .btn-modal-close {
        position: absolute;
        right: 16px;
        top: 10px;
        color: white;
        font-size: 1rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

.header-close {
    border-radius: 4px 4px 0 0;
    background-color: var(--black);
    width: 100%;

    .btn-modal-close {
        color: white;
        font-size: 1.2rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

.header-slide {

    /*.logo {
        width: 26%;
    }*/
    
    .logo {
        width: 70%;
    }
    
    .options {
        .icon-main {
            color: var(--white);
            transition: color .300s ease-in-out;
            font-size: $fontRegular;
            cursor: pointer;
            
            &:hover {
                color:  var(--gray);
            }
        }
    }

    .header-selects {
        .select-options {
            position: relative;
            margin-left: 16px;
            width: 250px;

            &:last-child {
                width: 200px;
            }
    
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                appearance: none;
                padding: 0 .5em;
                color: var(--black);
                cursor: pointer;
              }
              /* Remove IE arrow */
              select::-ms-expand {
                display: none;
              }

             
              /* Custom Select */
              .select {
                position: relative;
                display: flex;
                width: 100%;
                height: 2.5em;
                line-height: 3;
                background: var(--white);
                overflow: hidden;
                border-radius: 4px;
              }
         
              /* Arrow */
            .select-arrow{
                position: absolute;
                z-index: 2;
                top: 4px;
                right: 12px;
                border-radius: 0 4px 4px 0;
                height: 100%;
                font-size: $fontRegular;
                cursor: pointer;
                pointer-events: none;
                transition: .25s all ease;
                color: black;
            }
            
            &:hover {
                .select-arrow {
                    color: var(--gray);
                }
            }
        }
    }
  
}

.row-header{
    height: 50px;
    background-color: var(--white);
}